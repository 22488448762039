// src/BottomNav.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FaHome, FaNewspaper, FaMap, FaLeaf } from 'react-icons/fa'; // Use an appropriate icon for "Flower"

const StyledBottomNav = styled.nav`
  width: 100%;
  height: 60px;
  background-color: #101d40;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
`;

const NavItem = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
  text-decoration: none;
  color: white;

  &.active {
    color: #cd7f32; /* Bronze color */
  }

  &:hover {
    transform: translateY(-5px);
  }

  &:active {
    transform: translateY(0);
  }
`;

const Icon = styled.div`
  font-size: 24px;
`;

const Label = styled.div`
  font-size: 12px;
`;

function BottomNav() {
  return (
    <StyledBottomNav>
      <NavItem to="/" end>
        <Icon><FaHome /></Icon>
        <Label>Home</Label>
      </NavItem>
      <NavItem to="/map">
        <Icon><FaMap /></Icon>
        <Label>Map</Label>
      </NavItem>
      <NavItem to="/news">
        <Icon><FaNewspaper /></Icon>
        <Label>News</Label>
      </NavItem>
      <NavItem to="/flower"> {/* New nav item for Flower */}
        <Icon><FaLeaf /></Icon> {/* Use an appropriate icon */}
        <Label>Flower</Label>
      </NavItem>
    </StyledBottomNav>
  );
}

export default BottomNav;
