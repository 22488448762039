// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import moment from 'moment';
import EventList from './EventList';
import Reminder from './Reminder';
import TopNav from './TopNav';
import BottomNav from './botNav'; 
import logo from './logo.png';
import styled from 'styled-components';
import farm from './farm.jpg';
import Home from './Home';
import Map from './Map.js';
import News from './News';
import Flower from './Flower.js'; // Import the new Flower component
import ReactGA from 'react-ga4';
ReactGA.initialize('G-C5D4DDR5FK');

// Styled components for the IntroPopup
const IntroPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: ${(props) => (props.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const PopupContent = styled.div`
  width: 70%;
  max-width: 400px;
  padding: 20px;
  background: linear-gradient(58deg, rgba(2,0,36,1) 0%, rgba(16,29,64,1) 57%, rgba(17,46,124,1) 100%);
  border-radius: 10px;
  text-align: center;
  color: #fff;
  position: relative;
  margin-bottom: 150px;
`;

const BusinessLogo = styled.img`
  width: 250px;
  height: 150px;
  border-radius: 5%;
`;

const ToggleButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  z-index: 4;
`;

const App = () => {
  const [events] = useState([
    // Your events data
  ]);

  const [showIntroPopup, setShowIntroPopup] = useState(true);

  const closeIntroPopup = () => {
    setShowIntroPopup(false);
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <Router>
      <div className="App">
        <TopNav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/map" element={<Map />} />
          <Route path="/news" element={<News />} />
          <Route path="/flower" element={<Flower />} /> {/* New route for Flower */}
        </Routes>
        <BottomNav />
      </div>
    </Router>
  );
};

export default App;
