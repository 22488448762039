import React from 'react';
import styled from 'styled-components';

const IframeContainer = styled.div`
margin-top:50px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NewsIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const News = () => {
  return (
    <IframeContainer>
      <NewsIframe 
        src="/news.html" 
        title="News" 
      />
    </IframeContainer>
  );
};

export default News;
