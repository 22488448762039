import React, { useState } from 'react';
import moment from 'moment';
import EventList from './EventList';
import Reminder from './Reminder';
import TopNav from './TopNav';
import BottomNav from './botNav'; // Import BottomNav
import logo from './logo.png';
import styled from 'styled-components';
import farm from './amy.jpg';


// Styled components for the IntroPopup
const IntroPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: ${(props) => (props.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const PopupContent = styled.div`
  width: 70%;
  max-width: 400px;
  padding: 20px;
  background: linear-gradient(58deg, rgba(2,0,36,1) 0%, rgba(16,29,64,1) 57%, rgba(17,46,124,1) 100%);
  border-radius: 10px;
  text-align: center;
  color: #fff;
  position: relative;
  margin-bottom: 150px;
`;

const BusinessLogo = styled.img`
  width: 250px;
  height: 150px;
  border-radius: 5%;
`;

const ToggleButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  z-index: 4;
`;

const BookNowButton = styled.a`
  display: inline-block;
  padding: 12px 24px;
  font-size: 18px;
  color: #fff;
  background: linear-gradient(135deg, #9d6d49, #8a5e39);
  text-align: center;
  text-decoration: none;
  border-radius: 8px;
  margin-top: 20px;
  font-weight: bold;
  transition: background 0.3s ease, transform 0.2s ease;

  &:hover {
    background: linear-gradient(135deg, #8b5e40, #b68d59);
    transform: scale(1.05);
  }

  &:active {
    background: linear-gradient(135deg, #7a4b35, #a67850);
    transform: scale(0.95);
  }
`;

const App = () => {
  const [events] = useState([
    { id: 1, name: 'Burton Joyce Community Brass Band playing', time: '10:00:00', location: 'Cannonade', duration: 30 },
    { id: 2, name: 'Jonathan Mosely guest flower demonstration', time: '10:30:00', location: 'Marquee', duration: 30 },
    { id: 3, name: 'David Domoney guest speaker', time: '11:30:00', location: 'Marquee', duration: 30 },
    { id: 4, name: 'Jonny Victory singing', time: '12:30:00', location: 'Marquee', duration: 15 },
    { id: 5, name: 'Jonathan Mosely guest flower demonstration', time: '13:30:00', location: 'Marquee', duration: 15 },
    { id: 6, name: 'David Domoney guest speaker', time: '14:30:00', location: 'Marquee', duration: 30 },
    { id: 7, name: 'Jonny Victory singing', time: '15:30:00', location: 'Marquee', duration: 30 },
    { id: 8, name: 'Peoples choice award of the show borders', time: '15:30:00', location: 'Marquee', duration: 30 },
  ]);

  const [showIntroPopup, setShowIntroPopup] = useState(true);

  const closeIntroPopup = () => {
    setShowIntroPopup(false);
  };

  return (
    <div className="App">
      <TopNav />
      <EventList events={events} />
      {events.map(event => (
        <Reminder key={event.id} event={event} logo={logo} />
      ))}

      {/* Farmshop IntroPopup component */}
      <IntroPopup show={showIntroPopup}>
        <PopupContent>
          <ToggleButton onClick={closeIntroPopup}>
            ✖
          </ToggleButton>
          
          <BusinessLogo src={farm} alt="Business Logo" />
          <h2>Amy Winehouse Tribute</h2>
          <p>Book today using code FLOWER for 10% Off</p>
          <BookNowButton href="https://www.belvoircastle.com/singleevent/amy-winehouse-tribute/" target="_blank" rel="noopener noreferrer">
            Book Now
          </BookNowButton>
        </PopupContent>
      </IntroPopup>

 

      {/* BottomNav component */}
      <BottomNav />
    </div>
  );
};

export default App;
