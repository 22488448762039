import React, { useState, useEffect } from 'react';
import './Reminder.css'; // Import your CSS file for styling
import moment from 'moment';

const Reminder = ({ event, logo }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const eventTime = moment(event.time, 'HH:mm:ss');
    const tenMinutesBeforeEvent = eventTime.clone().subtract(10, 'minutes');

    const checkEventTime = () => {
      const now = moment();
      const currentTime = moment(now.format('HH:mm:ss'), 'HH:mm:ss');

      if (currentTime.isBetween(tenMinutesBeforeEvent, eventTime, null, '[)')) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    checkEventTime();

    const interval = setInterval(checkEventTime, 1000);

    return () => clearInterval(interval);
  }, [event]);

  return (
    visible && (
      <div className="reminder-popup visible">
        <img src={logo} alt="Logo" className="logo" />
        <div className="reminder-text">
          <h2>Upcoming Event</h2>
          <p>{event.name} is starting shortly at {event.location}</p>
        </div>
      </div>
    )
  );
};

export default Reminder;
