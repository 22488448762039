import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, ZoomControl, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import cafeIcon from './icons/cafe4.gif'; // Adjust the path as needed
import cowIcon from './icons/cow4.gif';   // Adjust the path as needed
import infoIcon from './icons/information4.gif'; // Adjust the path as needed
import restaurantIcon from './icons/restaurant4.gif'; // Adjust the path as needed
import pizzaIcon from './icons/pizza4.gif'; // New icon
import castleIcon from './icons/castle4.gif'; // New icon
import slideIcon from './icons/play4.gif'; // New icon
import stallIcon from './icons/stall4.gif'; // New icon
import parkIcon from './icons/park5.gif'; // New icon
import beaverIcon from './icons/beaver.png'; // New icon for current location
import cameraIcon from './icons/camera4.gif'; // New icon for camera
import glampingIcon from './icons/glamping4.gif'; // New icon for glamping
import treeIcon from './icons/tree4.gif'; // New icon for tree
import benchIcon from './icons/bench4.gif'; // New icon for bench
import birdIcon from './icons/bird.gif'; // New icon for bird
import dogIcon from './icons/dog.gif';
import toiletIcon from './icons/toilet.gif'; // Adjust the path as needed



const MapReactLeaflet = () => {
  const [position, setPosition] = useState([52.8949310807259, -0.7855854358448289]); // Default position
  const [userLocation, setUserLocation] = useState(null); // State for user location

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setPosition([position.coords.latitude, position.coords.longitude]);
          setUserLocation([position.coords.latitude, position.coords.longitude]); // Update user location
        },
        (error) => {
          console.error('Error getting location', error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  // Define positions for custom markers
  const cafePosition = [52.894488245421456, -0.7860799330989732];
  const cowPosition = [52.89624316196548, -0.7849808760834764];
  const infoPosition = [52.894577404192596, -0.7858781559831574];
  const bistroPosition = [52.895760662481706, -0.7865513103296018];
  const pizzaPosition = [52.89558887270556,-0.786290308029669];
  const castlePosition = [52.89469130772342, -0.7824687210064951];
  const slidePosition = [52.89422747553337, -0.7857719012814801];
  const stallPosition = [52.89596679486962, -0.7870881141558517];
  const parkPosition1 = [52.894258531786235, -0.7867883874483983]; // New park marker position
  const parkPosition2 = [52.89536860039135, -0.7868890329417577]; // New park marker position
  const cameraPosition = [52.894681965503466, -0.7863799093209956]; // New camera marker position
  const glampingPosition = [52.89592232844985, -0.7855120748316047]; // New glamping marker position
  const treePosition1 = [52.89538126435826, -0.7858589309291286]; // New tree marker position
  const treePosition2 = [52.894965349086796, -0.7857089242460935]; // New tree marker position
  const benchPosition = [52.89432239196472, -0.7848889124428338]; // New bench marker position
  const birdPosition = [52.89367583378091, -0.7852257978865551]; // New bird marker position
  const dogPosition = [52.89409254649131, -0.7832623977269804];
  const toiletPosition1 = [52.8950472675944, -0.7829701281834933];
const toiletPosition2 = [52.89473665090457, -0.7858850469498746];
const toiletPosition3 = [52.89582096707358, -0.7868657416741631];

  

  // Define the path coordinates
  const pathCoordinates = [
    [52.89456007787925, -0.7859485280385403],
    [52.89415081986201, -0.7854883839683735],
    [52.894372961492586, -0.7842527835809077]
  ];

  // Create custom icons
  const cafeMarkerIcon = new L.Icon({
    iconUrl: cafeIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  const toiletMarkerIcon = new L.Icon({
    iconUrl: toiletIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  const dogMarkerIcon = new L.Icon({
    iconUrl: dogIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });
  

  const cowMarkerIcon = new L.Icon({
    iconUrl: cowIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  const infoMarkerIcon = new L.Icon({
    iconUrl: infoIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  const bistroMarkerIcon = new L.Icon({
    iconUrl: restaurantIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  const pizzaMarkerIcon = new L.Icon({
    iconUrl: pizzaIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  const castleMarkerIcon = new L.Icon({
    iconUrl: castleIcon,
    iconSize: [42, 42],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  const slideMarkerIcon = new L.Icon({
    iconUrl: slideIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  const stallMarkerIcon = new L.Icon({
    iconUrl: stallIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  const parkMarkerIcon = new L.Icon({
    iconUrl: parkIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  const userMarkerIcon = new L.Icon({
    iconUrl: beaverIcon,
    iconSize: [42, 42], // Adjust size based on your icon's dimensions
    iconAnchor: [16, 32], // Anchor point for the icon
    popupAnchor: [0, -32] // Popup anchor point
  });

  const cameraMarkerIcon = new L.Icon({
    iconUrl: cameraIcon,
    iconSize: [32, 32], // Adjust size based on your icon's dimensions
    iconAnchor: [16, 32], // Anchor point for the icon
    popupAnchor: [0, -32] // Popup anchor point
  });

  const glampingMarkerIcon = new L.Icon({
    iconUrl: glampingIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  const treeMarkerIcon = new L.Icon({
    iconUrl: treeIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  const benchMarkerIcon = new L.Icon({
    iconUrl: benchIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  const birdMarkerIcon = new L.Icon({
    iconUrl: birdIcon,
    iconSize: [32, 32], // Adjust size based on your icon's dimensions
    iconAnchor: [16, 32], // Anchor point for the icon
    popupAnchor: [0, -32] // Popup anchor point
  });

  // Center the map on userLocation if it's available
  const MapCenter = ({ position }) => {
    const map = useMap();

    useEffect(() => {
      if (position) {
        map.setView(position, map.getZoom()); // Set the map view to the user's location
      }
    }, [position, map]);

    return null;
  };

  // Add a dashed polyline to the map
  const AddDashedPolyline = () => {
    const map = useMap();

    useEffect(() => {
      if (map) {
        L.polyline(pathCoordinates, {
          color: 'brown',
          weight: 1,
          opacity: 0.4,
          dashArray: '10, 10' // This makes the line dashed
        }).addTo(map);
      }
    }, [map]);

    return null;
  };

  return (
    <MapContainer
      center={position}
      zoom={18} // Set the zoom level to fully zoomed in
      maxZoom={20} // Ensure maximum zoom level is supported
      style={{ height: '100vh', width: '100%' }}
      zoomControl={false} // Hide zoom controls
      scrollWheelZoom={true} // Allow zooming with the scroll wheel
      dragging={true} // Ensure dragging is enabled
    >
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
      />
      <MapCenter position={userLocation} />
      <AddDashedPolyline />
      <Marker position={cafePosition} icon={cafeMarkerIcon}>
        <Popup>
          Vale View Cafe
          <br />
          Grab a quick snack and a bite to eat.
        </Popup>
      </Marker>
      <Marker position={cowPosition} icon={cowMarkerIcon}>
        <Popup>
          Animal Park
          <br />
          Coming Soon...
        </Popup>
      </Marker>
      <Marker position={infoPosition} icon={infoMarkerIcon}>
        <Popup>
          Ticket Office
          <br />
          Book in and find useful information here.
        </Popup>
      </Marker>
      <Marker position={bistroPosition} icon={bistroMarkerIcon}>
        <Popup>
          The Belvoir Bistro
          <br />
          Enjoy a fine dining experience.
        </Popup>
      </Marker>
      <Marker position={pizzaPosition} icon={pizzaMarkerIcon}>
        <Popup>
          Outside Pizza and Bar
          <br />
          Delicious pizzas available here.
        </Popup>
      </Marker>
      <Marker position={castlePosition} icon={castleMarkerIcon}>
        <Popup>
          Belvoir Castle
          <br />
          A historic castle with beautiful views.
        </Popup>
      </Marker>
      <Marker position={slidePosition} icon={slideMarkerIcon}>
        <Popup>
          Adventure Playground
          <br />
          Fun for the whole family!
        </Popup>
      </Marker>
      <Marker position={stallPosition} icon={stallMarkerIcon}>
        <Popup>
          Farm Shop
          <br />
          Fresh produce and local goods.
        </Popup>
      </Marker>
      <Marker position={parkPosition1} icon={parkMarkerIcon}>
        <Popup>
          Park Area 1
          <br />
          Parking for Castle, Garden and Adventure Playground visitors
        </Popup>
      </Marker>
      <Marker position={parkPosition2} icon={parkMarkerIcon}>
        <Popup>
          Park Area 2
          <br />
          Parking for Engine Yard Visitors
        </Popup>
      </Marker>
      <Marker position={cameraPosition} icon={cameraMarkerIcon}>
        <Popup>
          ANPR Cameras
          <br />
          In operation in this car park.
        </Popup>
      </Marker>
      <Marker position={glampingPosition} icon={glampingMarkerIcon}>
        <Popup>
          Glamping Site
          <br />
          Experience a luxurious camping experience.
        </Popup>
      </Marker>
      <Marker position={treePosition1} icon={treeMarkerIcon}>
        <Popup>
          Tree 1
          <br />
          Enjoy the natural beauty.
        </Popup>
      </Marker>
      <Marker position={treePosition2} icon={treeMarkerIcon}>
        <Popup>
          Tree 2
          <br />
          Another lovely spot.
        </Popup>
      </Marker>
      <Marker position={benchPosition} icon={benchMarkerIcon}>
        <Popup>
          Bench
          <br />
          Take a seat and relax.
        </Popup>
      </Marker>
      <Marker position={dogPosition} icon={dogMarkerIcon}>
  <Popup>
    Well Behaved Dogs
    <br />
    A great spot to walk your dog.
  </Popup>
</Marker>

      <Marker position={birdPosition} icon={birdMarkerIcon}>
        <Popup>
          Little Bird
          <br />
          
        </Popup>
      </Marker>
      <Marker position={toiletPosition1} icon={toiletMarkerIcon}>
  <Popup>
    Public Toilet
    <br />
    Convenient and clean facilities.
  </Popup>
</Marker>
<Marker position={toiletPosition2} icon={toiletMarkerIcon}>
  <Popup>
    Public Toilet
    <br />
    Accessible and well-maintained.
  </Popup>
</Marker>
<Marker position={toiletPosition3} icon={toiletMarkerIcon}>
  <Popup>
    Public Toilet
    <br />
    Accessible and well-maintained.
  </Popup>
</Marker>
      {userLocation && (
        <Marker position={userLocation} icon={userMarkerIcon}>
          <Popup>
            Your Location
            <br />
            This is where you are right now.
          </Popup>
        </Marker>
      )}
      <ZoomControl position="topright" />
    </MapContainer>
  );
};

export default MapReactLeaflet;
