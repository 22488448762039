import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled, { keyframes } from 'styled-components';
import Confetti from 'react-confetti';

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const StyledHeader = styled.h1`
  text-align: center;
  margin-top: 2rem;
  font-family: 'Cormorant', serif;
`;

const Underline = styled.div`
  width: 50%;
  height: 2px;
  background-color: #795548;
  margin: 0.5rem auto;
`;

const EventListContainer = styled.div`
  padding: 1rem;
  max-width: 800px;
  margin: 2rem auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const StyledListItem = styled.li`
  position: relative;
  padding: 1rem;
  margin: 0.5rem 0;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -12px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    animation: ${pulse} 1s ease-in-out infinite;
    display: ${(props) => (props.isLive ? 'block' : 'none')}; /* Conditionally show for live events */
  }
`;

const EventList = ({ events }) => {
  const [liveEventIds, setLiveEventIds] = useState([]);
  const [confettiActive, setConfettiActive] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment();
      const liveEventIds = events
        .filter(event => {
          const eventTime = moment(event.time, 'HH:mm:ss');
          const currentTime = moment(now.format('HH:mm:ss'), 'HH:mm:ss');
          return eventTime <= currentTime && currentTime < eventTime.clone().add(event.duration, 'minutes');
        })
        .map(event => event.id);

      setLiveEventIds(liveEventIds);

     
      if (liveEventIds.length > 0) {
        setConfettiActive(true);
        setTimeout(() => {
          setConfettiActive(false);
        }, 30000); 
      } else {
        setConfettiActive(false);
      }
    }, 1000); 

    return () => clearInterval(interval);
  }, [events]);

  return (
    <>
      {confettiActive && <Confetti />}
      <EventListContainer>
        <StyledHeader>Event Programme</StyledHeader>
        <Underline />
        <StyledList>
          {events.map((event) => {
            const isLive = liveEventIds.includes(event.id);

            return (
              <StyledListItem key={event.id} isLive={isLive}>
                <strong>{event.name}</strong>
                <span>
                  {moment(event.time, 'HH:mm:ss').format('h:mm:ss a')} at {event.location}
                </span>
              </StyledListItem>
            );
          })}
        </StyledList>
      </EventListContainer>
    </>
  );
};

export default EventList;
