// src/Flower.js
import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';

const styles = {
  container: {
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '100%',
    margin: '40px auto', 
    color: '#101d40',
  },
  title: {
    color: '#101d40',
    fontSize: '24px',
    marginBottom: '10px',
  },
  description: {
    fontSize: '16px',
    marginBottom: '20px',
    color: '#555',
  },
  webcamContainer: {
    position: 'relative',
  },
  webcam: {
    width: '100%',
    border: '2px solid #101d40',
    borderRadius: '8px',
  },
  captureButton: {
    marginTop: '10px',
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#101d40',
    color: '#ffffff',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  captureButtonHover: {
    backgroundColor: '#0d1b31',
  },
  loadingText: {
    fontSize: '18px',
    color: '#101d40',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    width: '90%',
    maxWidth: '500px',
    marginTop: '50px', 
  },
  closeButton: {
    marginTop: '10px',
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#101d40',
    color: '#ffffff',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  flowerInfoTitle: {
    fontSize: '20px',
    color: '#101d40',
  },
  flowerInfoText: {
    fontSize: '16px',
    color: '#333',
  },
};

const Flower = () => {
  const [imageSrc, setImageSrc] = useState(null);
  const [flowerInfo, setFlowerInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const webcamRef = useRef(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
    identifyFlower(imageSrc);
  }, [webcamRef]);

  const identifyFlower = async (imageSrc) => {
    setLoading(true);

    const base64Image = imageSrc.split(',')[1]; 
    const apiKey = 'lv4XQ5g8w0f96Pw5zjkiMlggC7BPlCPu0LNP07ei1NgXvxjCdr'; 

    try {
      const response = await axios.post('https://api.plant.id/v2/identify', {
        images: [base64Image],
        api_key: apiKey,
      });

      const { is_plant, suggestions } = response.data;
      if (is_plant) {
        const topSuggestions = suggestions.slice(0, 3).map(suggestion => ({
          name: suggestion.plant_name,
          probability: (suggestion.probability * 100).toFixed(2) + '%',
        }));
        
        const suggestionsText = topSuggestions.map(
          suggestion => `${suggestion.name} (${suggestion.probability})`
        ).join(', ');

        setFlowerInfo(`Top Suggestions: ${suggestionsText}`);
      } else {
        setFlowerInfo('The image does not appear to be a plant. Try again with a clearer image.');
      }
      setShowModal(true); 
    } catch (error) {
      if (error.response) {
        setFlowerInfo(`API Error: ${error.response.status} - ${error.response.data.error.message}`);
      } else if (error.request) {
        setFlowerInfo('No response from API. Please try again.');
      } else {
        setFlowerInfo('Error in setting up request. Please try again.');
      }
      setShowModal(true); 
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Flower Identification</h1>
      <p style={styles.description}>Use the camera to scan a flower on the estate and identify it.</p>
      
      <div style={styles.webcamContainer}>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          style={styles.webcam}
        />
        <button
          style={styles.captureButton}
          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.captureButtonHover.backgroundColor}
          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = styles.captureButton.backgroundColor}
          onClick={capture}
        >
          Capture
        </button>
      </div>
      
      {loading && <p style={styles.loadingText}>Loading...</p>}
      
      {showModal && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <h2 style={styles.flowerInfoTitle}>Flower Information</h2>
            <p style={styles.flowerInfoText}>{flowerInfo}</p>
            <button style={styles.closeButton} onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Flower;
