import React, { useState } from 'react';
import styled from 'styled-components';
import peacockLogo from './logo.png';
import { FaBars, FaTimes } from 'react-icons/fa';

const StyledTopNav = styled.nav`
  width: 100%;
  height: 60px;
  background: linear-gradient(58deg, rgba(2,0,36,1) 0%, rgba(16,29,64,1) 57%, rgba(17,46,124,1) 100%);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    height: 70px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 41%;
`;

const LogoImage = styled.img`
  height: 50px;
  width: auto;
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
`;

const MobileMenu = styled.div`
  width: 200px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  transition: left 0.3s ease;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    width: 250px;
  }
`;

const MenuHeader = styled.h3`
  color: white;
  font-size: 24px;
  margin-bottom: 20px;
`;

const MenuLink = styled.a`
  color: white;
  font-size: 18px;
  text-decoration: none;
  margin-bottom: 15px;

  &:hover {
    text-decoration: underline;
  }
`;

function TopNavigationBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <StyledTopNav>
        <MenuButton onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </MenuButton>
        <LogoContainer>
          <LogoImage src={peacockLogo} alt="Peacock Logo" />
        </LogoContainer>
      </StyledTopNav>
      <MobileMenu isOpen={isMenuOpen}>
        <MenuHeader>Coming 2025</MenuHeader>
        <MenuLink href="#">User Profile</MenuLink>
        <MenuLink href="#">Personalised Rewards</MenuLink>
        <MenuLink href="#">Benefits and More</MenuLink>
      </MobileMenu>
    </>
  );
}

export default TopNavigationBar;
